import { CustomCookieNamesEnum } from '~/types/cookies';

/**
 ** Convert cookie string to object
 */
export const cookieToObject = (cookie?: string | null): { [key: string]: string } =>
    cookie
        ? cookie.split('; ').reduce((acc, cur) => {
              const [name, ...value] = cur.split('=');
              return {
                  ...acc,
                  [name]: value.join('='),
              };
          }, {})
        : {};

export const setFunctionalCookieValue = (cookie: string, value: { value: any; expires: number }): void => {
    const functionalCookie = cookieToObject(document.cookie)?.functionalCookie;
    let newFunctional = {};
    if (!functionalCookie) {
        newFunctional = {
            [cookie]: value,
        };
    } else {
        newFunctional = {
            ...JSON.parse(atob(functionalCookie)),
            [cookie]: value,
        };
    }

    // Expire the whole functional cookie after one year
    const d = new Date();
    d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * 365);

    document.cookie = `functionalCookie=${btoa(
        JSON.stringify(newFunctional),
    )}; expires=${d.toUTCString()} ;path=/; SameSite=Strict; Secure`;
};

export const getFunctionalCookieValue = (attr: string): { value: any; expires: number } | undefined => {
    const functionalCookie = cookieToObject(document.cookie)?.functionalCookie;
    return functionalCookie ? JSON.parse(atob(functionalCookie))[attr] : undefined;
};

export const setRedirectCookieOnClient = (value?: string): void => {
    if (typeof window !== 'undefined') {
        document.cookie = `${CustomCookieNamesEnum.AllvitRedirect}=${
            value ?? btoa(JSON.stringify({ redirect: window.location.pathname + window.location.search }))
        }; max-age=120; path=/;`;
    } else {
        throw new Error('This function should only be called on the server');
    }
};
